<template>
  <layout-vertical>
    <router-view />
    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
        :name="name"
        :avatarUrl="avatar"
        @app-logout="logout"
      />
    </template>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import Navbar from '../components/Navbar.vue'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import md5 from 'md5'

export default {
  components: {
    LayoutVertical,
    Navbar
  },
  data() {
    return {
    }
  },
  computed: {
    name() {
      let user = store.getters['auth/user']
      if (user && user.attributes) {
        return user.attributes['name']
      }
      return ''
    },
    avatar() {
      let user = store.getters['auth/user']
      if (user && user.attributes) {
        let email = user.attributes['email']
        if (email) {
          const url = [
            'https://www.gravatar.com/avatar/',
            md5(email.toLowerCase().trim()),
            '?s=200',
            '&d=mp'
          ]
          
          return url.join('')
        }
      }
      return ''
    }
  },
  methods: {
    logout() {
      console.log('logout')
      store.dispatch('auth/signOut')
        .then(() => {
          this.$router.push('/login')
        })
        .catch((err) => {
            this.protectedUI = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
          })
    }
  }
}
</script>
